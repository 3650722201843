
var listingMap = [];
var listingMarkers = [];

var isTouchDevice = true;

function drawMapListing($location){
    var $tooltip = $('<div>',{class:'card listing'});

    $tooltip.append('<figure><img src="'+'https://www.goruralscotland.com'+$location.Image+'"></figure>');
    $tooltip.append('<main><div class="farm-name"><strong>'+$location.Category+'</strong ></div> <h4>'+$location.Title+'</h4></main>');
    $tooltip.find('main').append('<a class="cta" href="'+$location.Url+'">Check it out</a>');

    return $tooltip;
}

function add_location($location, map, i) {
    // Var
    var lat = $location.Latitude,
        lng = $location.Longitude,
        isFarm = $location.IsRealFarm,
        plotIcon = 'images/ui/logo-icon60x60.png',
        $plotIcon;

    if(isFarm == true){
        $plotIcon = {
                url:plotIcon,
                scaledSize: new google.maps.Size(45,45),
                origin: new google.maps.Point(0,0),
                anchor: new google.maps.Point(22.5,22.5)
            };
    } else{
        $plotIcon = {
                path: google.maps.SymbolPath.CIRCLE,
                fillOpacity: 1,
                fillColor: '#f35d23',
                strokeOpacity: 0,
                strokeColor: '#f35d23',
                strokeWeight: 0, 
                scale: 8
            };
    }

    var marker = new google.maps.Marker({
        icon: $plotIcon,
        position: new google.maps.LatLng(lat, lng),
        map: map,
        title: $location.Title
    });

    // Add to array
    map.markers.push(marker);

    // Get Z Index
    marker.set("myDepth", marker.getZIndex());

    listingMarkers[i] = marker;

    google.maps.event.addListener(marker, 'click', function() {
        if(isTouchDevice){
            marker.setOptions({zIndex:google.maps.Marker.MAX_ZINDEX + 1});
            generateTooltip(drawMapListing($location), true);
        } else{
            window.location = $location.Url;
        }
    });

    google.maps.event.addListener(marker, 'mouseover', function() {
        isTouchDevice = false;
        marker.setOptions({zIndex:google.maps.Marker.MAX_ZINDEX + 1});
        generateTooltip(drawMapListing($location), false);
    });
    google.maps.event.addListener(marker, 'mouseout', function() {
        marker.setOptions({zIndex:marker.get('myDepth')});
        destroyTooltip();
    });

}

function render_listing_map($map, items, i) {

    // Global i
    var x = i;

    var mapStyle = [];

    // This is the minimum zoom level that we'll allow
    var minZoomLevel = 15,
        maxZoomLevel = 18;

    var $mapCenter = new google.maps.LatLng(56.4603199808258, -4.050760135071022);


    var args = {
        center: $mapCenter,
        zoom: 16,
        scrollwheel: false,
        mapTypeControl: false,
        draggable: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles : mapStyle,
        disableDefaultUI: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER
        },
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        fullscreenControl: false
    };


    // Create map 

    listingMap[x] = new google.maps.Map(document.getElementById('map-listings'), args);

    // // Add a markers reference
    listingMap[x].markers = [];

    // // Add markers
    for (var i = 0; i < items.length; i++) {
     add_location(items[i], listingMap[x], i);
    }
    
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < listingMarkers.length; i++) {
     bounds.extend(listingMarkers[i].getPosition());
    }

    $('body').on('refreshMap',function(){
        listingMap[x].fitBounds(bounds);
    }).trigger('refreshMap')

    return listingMap[x];  
}

$(function(){
    
    // LOCAL TESTING (Check if php template)
    if(window.location.href.split('.')[1] == 'php'){
        $.getJSON( "json/accommodation-demo.json", function( response ) {
            $('#map-listings').each(function(i){
              var map = $(this);
              render_listing_map(map,response.coordinates,i);
            });
        });
    } else{
        console.log('If testing map, please check url against _map-listing.js line 142');
    }

});

