$(function () {

    //Reset scroll position
    $(window).scrollTop(0);

    var setMarker = (index, complete = false) => {
        var markerIcon = index === 0 ? { url: "./images/trail/Flag.png" } : { url: complete ? "./images/trail/Checked.png" : "./images/trail/Pin.png" };
        return markerIcon;
    }

    var setMarkerLabel = (index, emptyLabel = false) => {
        return {
            text: emptyLabel ? " " : (index).toString(),
            fontSize: "14px",
            className: "markerLabel",
            color: "#F35D23",
            fontFamily: "museo-slab, serif"
        }
    }

    function initMap() {

        const completePoints = [];
        const markers = [];
        let mapLegs = [];

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            polylineOptions: {
                strokeColor : "#F35D23"
            }
        });

        var trailCards = $(".trail-card-wrapper:not(#final-card) .trail-card");

        //Start point equal to first object in trail data array
        const startPoint = window.trailData[0];

        //End point equal to last object in trail data array
        const endPoint = window.trailData[0];

        //Waypoints
        const waypoints = window.trailData.map(x => {
            return {
                location: new google.maps.LatLng(x[1], x[0]),
            }
        })

        window.trailMap = new google.maps.Map(document.getElementById("trailmap"), {
            disableDefaultUI: true,
            minZoom: 0,
            zoom: 12
        });

        directionsService.route({
            origin: new google.maps.LatLng(startPoint[1], startPoint[0]),
            destination: new google.maps.LatLng(endPoint[1], endPoint[0]),
            waypoints: waypoints,
            travelMode: "DRIVING"
        }, (res, status) => {

            if (status == "OK") {

                directionsRenderer.setDirections(res);

                //Draw markers
                window.trailData.forEach((marker, index) => {

                    const newMarker = new google.maps.Marker({
                        position: new google.maps.LatLng(marker[1], marker[0]),
                        map: window.trailMap,
                        icon: setMarker(index, false),
                        label: setMarkerLabel(index, index == 0 ? true : false),
                        zIndex: index == 0 ? 1000 : 1000 - index,
                        id : index
                    });

                    markers.push(newMarker);

                    newMarker.addListener("click", function () {
                        $("html, body").animate({
                            scrollTop: $(".trail-card-wrapper")[this.id + 1].offsetTop
                        }, 500)
                    })
                });

                mapLegs = res.routes[0].legs;
            }

            directionsRenderer.setMap(window.trailMap)

            $(window).on("scroll", function () {

                var offset = 0;
    
                if (window.innerWidth <= 1023) {
                    offset = $(".trail-map").height();
                }
    
                trailCards.each(function (index) {
    
                    //Check if scrolled by
                    if ($(window).scrollTop() + offset >= ($(this).offset().top + ($(this).height() - $(this).find(".trail-card__distance").height()) / 2)) {
    
                        if (!completePoints.includes(index)) {
                            markers[index].setOptions({
                                icon: setMarker(index, true),
                                label: setMarkerLabel(index, true),
                                zIndex: 1 - 1000
                            });   

                            completePoints.push(index);
                        }                                           

                    } else {
                        
                        if (completePoints.includes(index)) {
                            markers[index].setOptions({
                                icon: setMarker(index, false),
                                label: setMarkerLabel(index, index === 0 ? true : false),
                                zIndex : index == 0 ? 1000 : 1000 - index
                            });                        

                            completePoints.splice(completePoints.indexOf(index), 1)
                        }

                    }
    
                })
    
            });
        });        

        //Check when map is initialized
        google.maps.event.addListenerOnce(window.trailMap, "idle", function () {
            $(".trail-map").addClass("ready");
        });

    }

    //Init Map
    if ($("#trailmap").length) {
        initMap();
    }

    //Share button
    $("#final-card a.cta").on("click", function () {
        $("#atic_more span:eq(0)").trigger("click");
    });

    //Sidebar fix
    document.documentElement.style.setProperty("--finalBoxHeight", $("#final-card").height() + "px");

})