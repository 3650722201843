$(function(){

	//options: Sleeps
	VirtualSelect.init({
		ele: '#sleeps-option',
		//options: getGroupOptions(),			
		//search: true,		
		placeholder: '<i class="fas fa-bed"></i> Sleeps 1-2',			
		options: [
			{ label: 'Sleeps 1-2', value: '1' },
			{ label: 'Sleeps 3-6', value: '2' },
			{ label: 'Sleeps 6-10', value: '3' },
			{ label: 'Sleeps 10+', value: '4' },
		]		
	});

	//options: All Locations
	VirtualSelect.init({
		ele: '#all-locations-option',
		placeholder: 'All Locations',			
		options: [
			{ label: 'Locations List', value: 'Locations List' },
			{ label: 'Aberdeen & Aberdeenshire', value: 'Aberdeen & Aberdeenshire' },
			{ label: 'Argyll and the Isles', value: 'Argyll and the Isles' }, 
			{ label: 'Ayrshire and Arran', value: 'Ayrshire and Arran' }, 
			{ label: 'Dumfries and Galloway', value: 'Dumfries and Galloway' }, 
			{ label: 'Dundee and Angus', value: 'Dundee and Angus' }, 
			{ label: 'Edinburgh and The Lothians', value: 'Edinburgh and The Lothians' }, 
			{ label: 'Fife', value: 'Fife' }, 
			{ label: 'Glasgow and the Clyde Valley', value: 'Glasgow and the Clyde Valley' }, 
			{ label: 'The Highlands', value: 'The Highlands' },			 
			{ label: 'Loch Lomond & The Trossachs', value: 'Loch Lomond & The Trossachs' }, 
			{ label: 'Moray Speyside', value: 'Moray Speyside' }, 
			{ label: 'Perthshire', value: 'Perthshire' },
			{ label: 'Scottish Borders', value: 'Scottish Borders' }, 
			{ label: 'Shetland, Orkney and the Outer Hebridies', value: 'Shetland, Orkney and the Outer Hebridies' }, 
			{ label: 'Stirlingshire & Forth Valley', value: 'Stirlingshire & Forth Valley' }, 
		]		
	});

	//options: Accommodation Type
	VirtualSelect.init({
		ele: '#accommodation-type-option',
		placeholder: 'All Accommodation',  			
		options: [
			{ label: 'Lodges, Chalets & Log Cabins', value: 'lodges-chalets-log-cabins' },
			{ label: 'Cottages And Houses', value: 'cottages-and-houses' },
			{ label: 'Glamping', value: 'glamping' },
			{ label: 'Caravans & Camping', value: 'caravans-camping' },
			{ label: 'Farmhouse B&b', value: 'farmhouse-bb' },
			{ label: 'Hostels', value: 'hostels' },
			{ label: 'Restaurants With Rooms', value: 'restaurants-with-rooms' },
			{ label: 'Unusual Buildings ', value: 'unusual-buildings' }
		]		
	});

	//options: All Attraction Type
	VirtualSelect.init({
		ele: '#attraction-type-option',
		placeholder: 'All Attraction Types',			
		options: [
			{ label: 'Places to Stay', value: 'places-to-stay' },
			{ label: 'Tours', value: 'tours' },
			{ label: 'Activities', value: 'activities' },
			{ label: 'Eat & Drink', value: 'eat-drink' },
			{ label: 'Gorural Trails', value: 'gorural-trails' },
			{ label: 'Events & Festivals On The Farm', value: 'events-festivals-on-the-farm' },
			{ label: 'Agricultural Shows', value: 'agricultural-shows' },
			{ label: 'Farmers Markets', value: 'farmers-markets' },
			{ label: 'Hold Your Event On Farm', value: 'hold-your-event-on-farm' },
		]		
	});

	//options: Best For Type
	VirtualSelect.init({
		ele: '#best-for-option',
		placeholder: 'Best For',			
		options: [
			{ label: 'Food Lovers Break', value: 'food-lovers-break' },
			{ label: 'Short break', value: 'short-break' },
			{ label: 'Activity break', value: 'activity-break' },
			{ label: 'Family break', value: 'family-break' },
			{ label: 'Couples break', value: 'couples-break' },
			{ label: 'Health farm/break', value: 'health-farm/break' },
			{ label: 'Wellness & relaxing break', value: 'wellness-relaxing-break' },
			{ label: 'Music, art & culture', value: 'music-art-culture' },
			{ label: 'Tours, trips & trails', value: 'tours-trips-trails' },
		]		
	});

	//options: When Type (Events)
	VirtualSelect.init({
		ele: '#when-option',
		placeholder: 'Select Month',			
		options: [
			{ label: 'July 2021', value: 'july-2021' },
			{ label: 'Aug 2021', value: 'aug-2021' },
			{ label: 'Sept 2021', value: 'sept-2021' },
			{ label: 'Oct 2021', value: 'oct-2021' },
			{ label: 'Nov 2021', value: 'nov-2021' },
			{ label: 'Dec 2021', value: 'dec-2021' },
		]		
	});

	//options: Event Types
	VirtualSelect.init({
		ele: '#event-types-option',
		placeholder: 'All Event types',			
		options: [
			{ label: 'On Farm Events and Festivals', value: 'events-and-festivals'},
			{ label: 'Scotland’s Agricultural Shows', value: 'agri-shows'},
			{ label: 'Farms that offer Royal Highland Education Trust Free School Visits', value: 'free-school-visits'},
			{ label: 'Farmers Markets', value: 'farmers-markets'},
			{ label: 'Farm tours ', value: 'farm-tours'},
			{ label: 'Cookery', value: 'cookery'},
			{ label: 'Farm distillery/brewery tours', value: 'distillery-brewery-tours'},
			{ label: 'Children’s Events', value: 'childrens-events'},
		]		
	});
});


