$(function(){
    $('.booking-summary .x-price .sub').click(function () {
        if ($(this).next('.form-control').val() == 0) {
            $('.modal#modalItemRemove').show();
            $(body).addClass('modal-open');
        }
    });
    $('.modal#modalItemRemove .modal-close').click(function () {
        $('.modal#modalItemRemove').hide();
        $(body).removeClass('modal-open');
    });
});