$(function () {
    if ($('.groupSlider').length > 0) {
        $('.groupSlider').flickity({
            cellAlign: 'right',
            wrapAround: false,
            groupCells: "true",
            contain: true,
            pageDots: false,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true,
        });
    };

    $("body").on("click", '.listing-slider.groupSlider .slider', function () {

        const index = $(this).parents(".listing-slider").index(".listing-slider");
        const newSlideItems = [];

        $(".groupSlider.listing-slider").eq(index).find(".slider").toArray().forEach((el, index) => {
            const figureImg = el.querySelector("figure").cloneNode(true);

            //If after first slide, repalce src with lazy load src attribute
            if (index > 0) {
                figureImg.querySelector("img").setAttribute("data-flickity-lazyload", figureImg.querySelector("img").getAttribute("data-high-res"))
                figureImg.querySelector("img").removeAttribute("src");
            } else {
                figureImg.querySelector("img").setAttribute("src", figureImg.querySelector("img").getAttribute("data-high-res"));
            }

            newSlideItems.push($("<div class='slider' />").append(figureImg));
        });

        if ($(".listing-slider-modal .listing-slider").hasClass("flickity-enabled")) {
            $(".listing-slider-modal .listing-slider").find(".slider").remove();
            $(".listing-slider-modal .listing-slider").flickity("destroy");
        }

        $(".listing-slider-modal .listing-slider").append(newSlideItems);
        $('.listing-slider-modal .listing-slider').flickity({
            cellAlign: 'right',
            wrapAround: false,
            groupCells: "true",
            contain: true,
            pageDots: false,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            lazyLoad: true
        });

        $(".listing-slider-modal").css("top", 0);
        $('body').addClass('preventScroll');

        $('.listing-slider-modal .listing-slider').flickity('select', $(this).index(), false, true); //select relevant slide to index of member item
    });

    $('.close').click(function () {
        $(this).parents("section.listing-slider-modal").css('top', '-150vh');
        $('body').removeClass('preventScroll');
    });

    if($('.singleSlider').length > 0)
    {
        $('.singleSlider').flickity({
            //options            
            cellAlign: 'left',
            wrapAround: true,
            groupCells: "100%",
            contain: true,
            pageDots: true,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true,
        });        
    };
});

const initListingSlider = () => {
    if ($('.groupSlider.listing-slider').length > 0) {
        $(".groupSlider.listing-slider").each(function () {
            $(this).flickity({
                cellAlign: 'right',
                wrapAround: false,
                groupCells: "true",
                contain: true,
                pageDots: false,
                prevNextButtons: true,
                imagesLoaded: true,
                adaptiveHeight: true,
            });
        })
    };
}

window.addEventListener("listingSliderInit", initListingSlider)