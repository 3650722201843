$(function(){
    var mapStyle = [];
    
    function initMap() {
        var loc = new google.maps.LatLng( parseFloat( $('#map').data('lat')), parseFloat( $('#map').data('lng')) ); 


        var mapOptions = {
            zoom: parseInt($('#map').data('zoom')),
            center: loc,
            styles: mapStyle,
            streetViewControl : false,
            rotateControl : false,
            fullscreenControl : false,
            mapTypeControl : false,
            zoomControl : $(".tabInfo #map").length ? true : false
        }; 
        var map = new google.maps.Map(document.getElementById('map'), mapOptions);        
        var image1 = 'images/map-icon.png';                
        var marker = new google.maps.Marker({
            position: loc,
            map: map,
            icon: image1
        });        
    }

    if ($('#map').length) { initMap(); }
});