
var singleMap = [];
var singleMarkers = [];

var isTouchDevice = true;

function getMapIcon(cat){
    var icon;
    switch(cat){
        case 'mountain':
            icon = '\uf6fc' // mountain
            break;
        case 'loch':
            icon = '\uf773';
            break;
        case 'charging':
            icon = '\uf5e7';
            break;
        case 'beach':
            icon = '\uf5ca';
            break;
        default:
            icon = '\uf005' // star;
    }
    return icon;
}

function add_single_location($location, map, i) {
    // Var
    var lat = $location.Latitude,
        lng = $location.Longitude,
        type = $location.Type,
        $plotIcon;

    if(type == 'listing'){
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map: map,
            icon: 'images/map-icon.png',
            title: $location.Title
        });
        map.setCenter(new google.maps.LatLng(lat, lng));
    } else{
         var marker = new google.maps.Marker({
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillOpacity: 1,
                fillColor: '#333',
                strokeOpacity: 0,
                strokeColor: '#333',
                strokeWeight: 0, 
                scale: 14,
                labelOrigin: new google.maps.Point(0.1,3.1)
            },
            label: {
                fontFamily: '"Font Awesome 5 Pro"',
                text: getMapIcon($location.Category),
                fontWeight: '900',
                fontSize:'12px',
                color: '#FFF'
            },
            position: new google.maps.LatLng(lat, lng),
            map: map,
            title: $location.Title
        });
    }

    // Add to array
    map.markers.push(marker);

    // Get Z Index
    marker.set("myDepth", marker.getZIndex());

    singleMarkers[i] = marker;

    google.maps.event.addListener(marker, 'click', function() {
        var dest = 'https://www.google.com/maps/search/?api=1&query=' + lat +  ',' + lng;
        window.open(dest, '_blank').focus();
    });

    google.maps.event.addListener(marker, 'mouseover', function() {
        marker.setOptions({zIndex:google.maps.Marker.MAX_ZINDEX + 1});
        if(type == 'listing'){
            generateTooltip('<strong>'+$location.Title+'</strong><span>Get Directions</span>', false, true);
        } else{
            generateTooltip('<strong>'+$location.Title+'</strong>', false, true);
        }
    });
    google.maps.event.addListener(marker, 'mouseout', function() {
        marker.setOptions({zIndex:marker.get('myDepth')});
        destroyTooltip();
    });

}

function render_single_map($map, items, i) {

    // Global i
    var x = i;

    var mapStyle = [];

    // This is the minimum zoom level that we'll allow
    var minZoomLevel = 15,
        maxZoomLevel = 18;

    var $mapCenter = new google.maps.LatLng(56.4603199808258, -4.050760135071022);


    var args = {
        center: $mapCenter,
        zoom: 11,
        scrollwheel: false,
        mapTypeControl: false,
        draggable: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles : mapStyle,
        disableDefaultUI: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER
        },
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        fullscreenControl: false
    };


    // Create map 

    singleMap[x] = new google.maps.Map(document.getElementById($map.attr('id')), args);

    // // Add a markers reference
    singleMap[x].markers = [];

    // // Add markers
    for (var i = 0; i < items.length; i++) {
     add_single_location(items[i], singleMap[x], i);
    }

    $('body').on('refreshMap',function(){
        // 
    }).trigger('refreshMap')

    return singleMap[x];  
}

$(function(){
    
        $('#map-single').each(function(i){
            var mapsrc = $(this).attr('data-src'),
                $map = $(this);
            $.getJSON( mapsrc, function( response ) {
              render_single_map($map,response.coordinates,i);
            });
        });

});

