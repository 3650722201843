/*--- Click to Top Scroll ---*/
$(function(){
    if ($(".scroll-down").length) {
        $(".scroll-down").click(function(){
            var pageId = $(this).attr("data-page");
            $("html, body").animate({ scrollTop: $("#"+pageId).offset().top }, 1000);
        });
    }
});

