// $(document).ready(function () {
//     //loading message on form submit
//     $('.checkout-details-form form').on('submit', (e) => {
//         var $saving = $('<p class="form-saving-message"><span class="loading-spinner"></span>Please wait, your payment is being processed</p>');
//         $('body').addClass('form-saving').append($saving);
//     });


//     //loading message for accomodation listings
//     if($('#availableAccomodation .listing-accomodation').length === 0){
//         $('#availableAccomodation .farm-filter-result').append('<p class="loading-message">Loading available listings</p>');
//     }else{
//         $('.loading').hide();
//     }

//     $('#availableAccomodation #ulResults').change(function(){
//         $('.loading').hide();
//     });
// });