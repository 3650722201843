$(function(){

  // ADD SOCIAL TO MENU

  $('.list-social').first().clone().prependTo('nav#nav-mobile > ul');

  // INIT MOB MENU
    $('nav#nav-mobile').removeClass('hidden').mmenu({
	    "extensions": [
              "position-right",
              "theme-dark"
           ]
    });
    var $menu = $('nav#nav-mobile').data("mmenu");

    $('.toggle-menu').click(function(){
       $menu.open();
    });
});